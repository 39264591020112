<template>
  <v-dialog
    :value="openedValue"
    max-width="700px"
    @click:outside="close()"
    @keydown.esc="close()"
  >
    <v-card
      :loading="loading"
    >
      <v-card-title>Modifier les informations de logisticien</v-card-title>
      <v-card-subtitle>
        <v-alert
          dense
          outlined
          text
          type="info"
          class="mt-3"
        >
          Mise à jour des informations du colis/bordereau
        </v-alert>
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="12"
            sm="12"
          >
            <v-select
              v-model="providerValue"
              label="Logisticien"
              :items="providers"
              item-text="label"
              item-value="value"
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row
          v-if="providerValue !== 'EXTERNAL' && providerValue !== 'BERRY_SERVICES'"
        >
          <v-col
            cols="12"
            md="12"
            sm="12"
          >
            <v-text-field
              v-model="providerReferenceValue"
              label="Référence Logisticien"
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row
          v-else
        >
          <v-col
            cols="12"
            md="6"
            sm="12"
          >
            <CarrierSelector
              :state.sync="carrierValue"
              dense
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
            sm="12"
          >
            <v-text-field
              v-model="trackingNumberValue"
              label="N° de suivi"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          outlined
          color="red darken-2"
          @click="close()"
        >
          <v-icon left>
            mdi-cancel
          </v-icon>{{ $t('forms.buttons.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          raised
          color="green darken-1"
          class="white--text"
          @click="save()"
        >
          <v-icon left>
            mdi-check-outline
          </v-icon>{{ $t('forms.buttons.validate') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CarrierSelector from '@/components/base/Shipment/Input/CarrierSelector.vue'

export default {
  name: 'ProviderModal',
  components: { CarrierSelector },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    shipmentId: {
      type: String,
      default: null,
    },
    provider: {
      type: String,
      default: null,
    },
    carrier: {
      type: String,
      default: null,
    },
    providerReference: {
      type: String,
      default: null,
    },
    trackingNumber: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      message: {
        type: null,
        text: null,
      },
      providers: [
        { label: 'Sendcloud', value: 'SENDCLOUD' },
        { label: 'Wing (B2B)', value: 'WING_B2B' },
        { label: 'Berry Services', value: 'BERRY_SERVICES' },
        { label: 'Externe', value: 'EXTERNAL' },
      ],
    }
  },
  computed: {
    openedValue: {
      get() { return this.opened },
      set(value) { this.$emit('update:opened', value) },
    },
    providerValue: {
      get() { return this.provider },
      set(value) { this.$emit('update:provider', value) },
    },
    providerReferenceValue: {
      get() { return this.providerReference },
      set(value) { this.$emit('update:providerReference', value) },
    },
    carrierValue: {
      get() { return this.carrier },
      set(value) { this.$emit('update:carrier', value) },
    },
    trackingNumberValue: {
      get() { return this.trackingNumber },
      set(value) { this.$emit('update:trackingNumber', value) },
    },
  },
  methods: {
    close() {
      this.$emit('shipmentUpdated', this.message)
      this.loading = false
      this.openedValue = false
    },
    save() {
      this.loading = true
      this.$axios.put('v3/shipments/' + this.shipmentId + '/provider', {
        provider: this.providerValue,
        provider_reference: this.providerReferenceValue,
        carrier: this.carrierValue,
        tracking_number: this.trackingNumberValue,
      })
        .then(() => {
          this.message.type = 'green'
          this.message.text = this.$i18n.t('views.shipment.forms.messages.save_succeed')
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error)
          let error_message = error.response.data['hydra:description'] ?? error.response.data['detail'] ?? 'No context'
          this.message.type = 'error'
          this.message.text = this.$i18n.t('views.shipment.forms.messages.save_failed', { error: error_message })
        }).finally(() => {
          this.close()
        })
    },
  },
}
</script>

<style scoped>

</style>
